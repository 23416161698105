<template>
  <div>
    <PageHeader :showFilter="false" />
    <div v-if="auctioneerList" class="auction-listings">
      <AuctionCard
        v-for="auction in auctioneerList"
        :key="auction._id"
        :auction="auction"
      />
    </div>
    <WhenThingsGoWrong
      v-else-if="showWhenThingsGoWrong"
      :requestError="requestError"
      :needButton="true"
      :text="errorText"
    />
    <Spinner v-else />
    <!-- <div class="pagination-div">
      <Pagination :pages="totalPages" />
    </div> -->
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue'
  import AuctionCard from '../components/AuctionCard.vue'
  import httpRequest from '../../../sharedHelpers/httpRequest'
  import { API_TRUCK } from '../../../sharedConfigs/apiConfig'
  import Spinner from '@/components/Spinner'
  import { setStatusTag } from '../../../sharedHelpers/prerenderTags'
  import {
    add as addNoIndexMetaTag,
    remove as removeNoIndexMetaTag
  } from '../../../sharedHelpers/noIndexMetaTag'
  import { siteName } from '@/config/siteSpecificConfig'
  import {
    textForWhenThingsGoWrong,
    truckDescriptionBySite
  } from '../../../sharedConfigs/miscConfig'
  // import Pagination from '../components/Pagination'
  export default {
    data: () => {
      return {
        auctioneerList: null,
        showWhenThingsGoWrong: false,
        requestError: false,
        errorText: textForWhenThingsGoWrong.requestError
      }
    },
    name: 'AuctionLists',
    components: {
      AuctionCard,
      WhenThingsGoWrong: defineAsyncComponent(() =>
        import('../components/WhenThingsGoWrong.vue')
      ),
      Spinner
      // Pagination
    },
    async created() {
      try {
        let auctioneerList = (
          await httpRequest({
            url: `${API_TRUCK.url}${API_TRUCK.endpoints.getClients.endpoint}`,
            method: 'post',
            data: {
              auctioneer: true
            }
          })
        ).data[API_TRUCK.endpoints.getClients.responseKeyName]
        if (auctioneerList.length) {
          this.auctioneerList = []
          let curDate = new Date().toISOString()
          for (
            let i = 0, loopTimes = auctioneerList.length;
            i < loopTimes;
            i++
          ) {
            let auctioneer = auctioneerList[i]
            if (curDate <= auctioneer.dates.startdate) {
              let { _id, dates, name, weburl, mailingaddress } = auctioneer
              this.auctioneerList.push({
                _id,
                dates,
                name,
                weburl,
                mailingaddress
              })
            }
          }
        } else {
          this.showWhenThingsGoWrong = true
          this.errorText =
            'There is no auction available, please check back later!'
        }
      } catch (error) {
        this.showWhenThingsGoWrong = this.requestError = true
      } finally {
        if (!this.auctioneerList || this.auctioneerList.length === 0) {
          addNoIndexMetaTag()
          setStatusTag(404)
        } else {
          setStatusTag(200)
          removeNoIndexMetaTag()
        }
        document
          .querySelector('meta[name="description"]')
          .setAttribute(
            'content',
            `Check out Auctions for ${truckDescriptionBySite[siteName].truckDescription} throughout US`
          )
        document.title = `Light And Medium Duty Commercial Truck Auctions | ${truckDescriptionBySite[siteName].siteNameTitleCase}`
        document
          .querySelector('link[rel="canonical"]')
          .setAttribute('href', `https://${siteName}.com${this.$route.path}`)
      }
    },
    beforeUnmount() {
      removeNoIndexMetaTag()
    }
  }
</script>

<style lang="scss">
  .auction-listings {
    @include containerWidth;
    @include containerPosition;
    @include displayGrid(repeat(3, 1fr));
  }
  @media screen and (max-width: 1100px) {
    .auction-listings {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media screen and (max-width: 750px) {
    .auction-listings {
      grid-template-columns: repeat(1, 1fr);
    }
  }
</style>
